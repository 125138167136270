import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import BankInstitution from './Components/bank';
import Account from './Components/account';

const App = () => {
  return (
    <Router>
      <div>
      <header className="header">
  <img
    fetchpriority="high"
    decoding="async"
    width="200"
    height="40"
    src="https://wearemacs.com/wp-content/uploads/2023/09/MACS-logo-dark-bg-transparent.png"
    className="attachment-full size-full wp-image-2433"
    alt="MACS logo"
    srcSet="https://wearemacs.com/wp-content/uploads/2023/09/MACS-logo-dark-bg-transparent.png 1376w, https://wearemacs.com/wp-content/uploads/2023/09/MACS-logo-dark-bg-transparent-300x68.png 300w, https://wearemacs.com/wp-content/uploads/2023/09/MACS-logo-dark-bg-transparent-1024x231.png 1024w, https://wearemacs.com/wp-content/uploads/2023/09/MACS-logo-dark-bg-transparent-768x173.png 768w"
    sizes="(max-width: 1376px) 100vw, 1376px"
    style={{ padding: '10px' }}
  />
</header>

        <Routes>
          <Route path="/" element={<BankInstitution />} />
          <Route path="/accounts" element={<Account />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
