import React, { useEffect, useState } from "react";
import Select from "react-select";
import WorldFlag from "react-world-flags";
import { generateToken, fetchBankInstitutions, createAgreement, fetchAccounts } from "../Api/goCardLessApi";
import "../App.css";
import { ClipLoader } from "react-spinners"; 

const BankInstitution = () => {
  const [token, setToken] = useState(null);
  const [institutions, setInstitutions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("FR");
  const [bankSearchQuery, setBankSearchQuery] = useState("");
  const [selectedBankId, setSelectedBankId] = useState(null);
  const [iban, setIban] = useState(null);
  const [isFetchingIban, setIsFetchingIban] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false); 

    useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get("email");
    if (email) {
      localStorage.setItem("user_email", email);
    }
  }, []);
  
  const countryList = [
    { code: "AT", name: "Autriche" },
    { code: "FI", name: "Finlande" },
    { code: "DK", name: "Danemark" },
    { code: "FR", name: "France" },
    { code: "DE", name: "Allemagne" },
    { code: "NO", name: "Norvège" },
    { code: "ES", name: "Espagne" },
    { code: "GB", name: "Royaume-Uni" },
  ];

  const fetchBankInstitutionsData = async (countryCode) => {
    if (!token || !countryCode) return;
    setLoading(true);
    try {
      const fetchedInstitutions = await fetchBankInstitutions(token, countryCode);
      const uniqueInstitutions = Array.from(new Set(fetchedInstitutions.map((inst) => inst.id)))
        .map((id) => fetchedInstitutions.find((inst) => inst.id === id));
      setInstitutions(uniqueInstitutions);
    } catch (error) {
      console.error("Erreur lors de la récupération des institutions bancaires :", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const loadToken = async () => {
      try {
        const secret_id = process.env.REACT_APP_SECRET_ID;
        const secret_key = process.env.REACT_APP_SECRET_KEY;
        const generatedToken = await generateToken(secret_id, secret_key);
        setToken(generatedToken);
      } catch (error) {
        console.error("Erreur lors de la génération du token :", error);
      }
    };
    loadToken();
  }, []);

  useEffect(() => {
    if (token) {
      fetchBankInstitutionsData(selectedCountry);
    }
  }, [token, selectedCountry]);

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption.code);
    setInstitutions([]);
    fetchBankInstitutionsData(selectedOption.code);
  };

  const handleBankSelect = async (institution) => {
    setSelectedBankId(institution.id); 
    setIsProcessing(true); 
    try {
      const response = await createAgreement(token, institution.id, selectedCountry);
      if (response.requisition_id) {
        localStorage.setItem('requisition_id', response.requisition_id);
      }
      if (response.link) {
        window.location.href = response.link; 
      } else {
        console.error("Aucun lien trouvé dans la réponse.");
      }
    } catch (error) {
      console.error("Erreur lors de la création de l'accord :", error);
    } finally {
      setSelectedBankId(null); 
      setIsProcessing(false); 
    }
  };

  const fetchIbanFromUrl = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const referenceId = urlParams.get("ref");
    const requisitionId = localStorage.getItem('requisition_id');
    const userEmail = localStorage.getItem('user_email');
    if (referenceId && token) {
      setIsFetchingIban(true);

      try {
        const ibanData = await fetchAccounts(token, referenceId, requisitionId);
        if (ibanData) {
          localStorage.removeItem('requisition_id');
          const redirectUrl = `https://wearemacs.com/connect-fraudes/?iban=${ibanData}`;
          const finalUrl = userEmail ? `${redirectUrl}&email=${userEmail}` : redirectUrl;
          window.location.href = finalUrl;
        } else {
          console.error("Connexion échouée.");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération de l'IBAN :", error);
        console.error("Connexion échouée.");
      } finally {
        setIsFetchingIban(false);
      }
    }
  };

  useEffect(() => {
    fetchIbanFromUrl();
  }, [token]);

  const countryOptions = countryList.map((country) => ({
    value: country.code,
    label: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <WorldFlag code={country.code} style={{ width: 20, marginRight: 10 }} />
        {country.name}
      </div>
    ),
    code: country.code,
  }));

  const filteredBanks = institutions.filter((institution) =>
    institution.name.toLowerCase().includes(bankSearchQuery.toLowerCase())
  );

  return (
    <div
      className="bank-selector-container"
      style={{
        // borderLeft: "50px solid #07122E",
        // borderRight: "50px solid #07122E",
        borderBottom: "50px solid  #07122E",
        padding: "10px",
      }}
    >
      <div className="main-card">
        <h1>Sélectionnez votre banque</h1>

        <div
          className="country-selection"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            marginTop: "20px",
          }}
        >
          <Select
            options={countryOptions}
            value={countryOptions.find(
              (option) => option.code === selectedCountry
            )}
            onChange={handleCountryChange}
            className="country-dropdown"
          />

          <input
            type="text"
            placeholder="Rechercher une banque..."
            value={bankSearchQuery}
            onChange={(e) => setBankSearchQuery(e.target.value)}
            className="bank-search-input"
          />
        </div>

        <h2>{`Banques en ${countryList.find((country) => country.code === selectedCountry)?.name}`}</h2>

        {!loading && filteredBanks.length > 0 && (
          <div className="bank-list">
            {filteredBanks.map((institution) => (
              <div className="bank-card" key={institution.id}>
                <button
                  className="bank-button"
                  onClick={() => handleBankSelect(institution)}
                  disabled={isProcessing} 
                  style={{ opacity: isProcessing ? 0.5 : 1 }} 
                >
                  <img
                    src={`${institution.logo}`}
                    alt={`Logo de ${institution.name}`}
                    className="bank-flag"
                  />
                  <h3 className="bank-name">{institution.name}</h3>
                </button>
              </div>
            ))}
          </div>
        )}
        {!loading && filteredBanks.length === 0 && (
          <p>Aucune banque trouvée pour ce pays.</p>
        )}

        {isProcessing && (
          <div className="spinner-overlay">
            <ClipLoader color="#0f114a" size={50} />
          </div>
        )}
      </div>
    </div>
  );
};

export default BankInstitution;
