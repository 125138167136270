import axios from 'axios';

const BASE_URL = process.env.REACT_APP_PUBLIC_API_URL || 'https://macsbe.vercel.app';

export const generateToken = async (secretId, secretKey) => {
  try {
    const response = await axios.post(`${BASE_URL}/api/generate-token`, {
      secret_id: secretId,
      secret_key: secretKey,
    }, {
      headers: {
        'accept': 'application/json',
        'Content-Type': 'application/json',
      },
    });

    return response.data.access;
  } catch (error) {
    console.error('Error generating token:', error);
    throw error;
  }
};

export const fetchBankInstitutions = async (token, country) => {
  try {

    const response = await axios.get(`${BASE_URL}/api/bank-institutions?country=${country}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'accept': 'application/json',
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching bank institutions from backend:', error);
    throw error;
  }
};

export const createAgreement = async ( token, institution_id, country_code) => {
  try {
    const response = await axios.post(`${BASE_URL}/api/agreements`, {
      institution_id, 
      country_code
    }, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'accept': 'application/json',
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error generating token:', error);
    throw error;
  }
};


export const fetchAccounts = async (token, referenceId , requisitionId) => {
  try {

    const response = await axios.get(`${BASE_URL}/api/accounts?referenceId=${referenceId}&requisitionId=${requisitionId}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'accept': 'application/json',
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching bank institutions from backend:', error);
    throw error;
  }
};



export const fetchBankAccounts = async (token, requisitionId) => {
  try {

    const response = await axios.get(`${BASE_URL}/api/bank-accounts?requisitionId=${requisitionId}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'accept': 'application/json',
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching bank institutions from backend:', error);
    throw error;
  }
};



