import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchBankAccounts } from "../Api/goCardLessApi";

const Account = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [bankAccounts, setBankAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getQueryParams = (queryString) => {
    return new URLSearchParams(queryString);
  };

  useEffect(() => {
    const queryParams = getQueryParams(location.search);
    const token = queryParams.get("token");
    const requisitionId = queryParams.get("requisitionId");

    const fetchAccounts = async () => {
      try {
        const accounts = await fetchBankAccounts(token, requisitionId);
        setBankAccounts(accounts);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching bank accounts:", error);
        setError("Failed to load bank accounts.");
        setLoading(false);
      }
    };

    fetchAccounts();
  }, [location.search, navigate]);

  if (loading) {
    return <div>Loading bank accounts...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <h1>Bank Accounts</h1>
      {bankAccounts.length > 0 ? (
        <ul>
          {bankAccounts.map((account) => (
            <li key={account.id}>
              <h3>{account.bankName}</h3>
              <p>Account ID: {account.id}</p>
              <p>IBAN: {account.iban}</p>
              <p>Currency: {account.currency}</p>
            </li>
          ))}
        </ul>
      ) : (
        <p>No bank accounts available.</p>
      )}
    </div>
  );
};

export default Account;
